import { Loader } from '@googlemaps/js-api-loader';
import React, { ReactElement, useEffect, useState } from 'react';
import { WrapperProps } from '@src/types/map';
import { Status } from '@src/constants/map';

/**
 * A component to wrap the loading of the Google Maps JavaScript API.
 */
const MapWrapper = ({
  children,
  render,
  callback,
  ...options
}: WrapperProps): ReactElement => {
  const [status, setStatus] = useState(Status.LOADING);

  useEffect(() => {
    const loader = new Loader(options);

    const setStatusAndExecuteCallback = (status: Status) => {
      if (callback) {
        callback(status, loader);
      }
      setStatus(status);
    };

    setStatusAndExecuteCallback(Status.LOADING);

    loader.load().then(
      () => setStatusAndExecuteCallback(Status.SUCCESS),
      () => setStatusAndExecuteCallback(Status.FAILURE)
    );
    // Dependency needs to be empty to prevent re-render loop. Example usage from library:
    // https://github.com/googlemaps/react-wrapper/blob/cc98812a92039cf6e509892e14dadef551b12fb4/src/index.tsx#L87
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === Status.SUCCESS && children) {
    return <>{children}</>;
  }

  if (render) {
    return render(status);
  }

  return <></>;
};

export default MapWrapper;
