import React, { useEffect, useState } from 'react';
import { MapMarkerType } from '@src/types/map';
import MapMarkerImg from '@public/assets/map-marker.svg';

const Marker: React.FC<
  google.maps.MarkerOptions & {
    updatePopupCard?: (data: MapMarkerType) => void;
  } & MapMarkerType
> = (options) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    } else {
      marker.addListener('click', () => {
        if (options.updatePopupCard) {
          const popupCardContent = {} as MapMarkerType;
          popupCardContent.position = options.position;
          popupCardContent.title = options.title;
          popupCardContent.image = options.image;
          popupCardContent.slug = options.slug;
          popupCardContent.theme = options.theme;

          options.updatePopupCard(popupCardContent);
        }
      });
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marker]);

  useEffect(() => {
    if (marker) {
      // setting icon here as we get an error when setting while iterating
      const icon = {
        url: MapMarkerImg.src,
        scaledSize: new google.maps.Size(50, 50)
      };
      marker.setIcon(icon);
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

export default Marker;
