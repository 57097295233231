import Heading from '@src/elements/Heading';
import { CtaFragment } from '@src/graphql/gql-types';
import { COLOR_SECONDARY } from '@src/types/colors';
import classNames from 'classnames';
import React from 'react';
import styles from './HeroContent.module.scss';
import CTAButton from '@src/components/Button/CTAButton';

interface HeroContent {
  heading?: string;
  description?: string;
  cta?: CtaFragment;
  slate?: boolean;
}

const HeroContent: React.FC<HeroContent> = ({
  heading,
  description,
  cta,
  slate
}) => {
  return (
    <div>
      <Heading
        priority={1}
        fontStyle="h1"
        className={classNames(styles.heroTitle, {
          [styles.headingSlate]: slate
        })}
      >
        {heading}
      </Heading>
      <Heading
        priority={2}
        fontStyle="h2"
        className={classNames(styles.heroDescription, {
          [styles.descriptionSlate]: slate
        })}
      >
        {description}
      </Heading>
      {cta && (
        <CTAButton color={COLOR_SECONDARY} cta={cta} label={cta.label || ''} />
      )}
    </div>
  );
};

export default HeroContent;
